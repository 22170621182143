<template>
  <div class="list-view product-checkout mt-0">
    <template>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refStepPaySlipSettingObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <section class="invoice-add-wrapper">
            <b-card
              title="Facturatie & verloning"
            >
              <b-row>
                <b-col cols="12">
                  <b-alert
                    :show="minimumTariffCheckError"
                    variant="danger"
                    class="mb-1"
                  >
                    <div class="alert-body">
                      <feather-icon
                        icon="InfoIcon"
                        class="mr-50"
                      />
                      Dit factuur voldoet mogelijk niet aan het minimumtarief van {{minimumTariff}}
                    </div>
                  </b-alert>
                </b-col>
                <b-col cols="12" lg="6">
                  <label class="col-form-label"><strong>Omschrijving factuur</strong></label>
                  <div>Vul hier de omschrijving in.</div>
                </b-col>
                <b-col cols="12" lg="6" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Omschrijving"
                    rules="required"
                  >
                    <b-form-group
                      label="Omschrijving"
                      label-for="description"
                    >
                      <b-form-input
                        id="description"
                        v-model="settingsData.description"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="6">
                  <label class="col-form-label"><strong>Referentie factuur</strong></label>
                  <div>Vul hier een referentie in die op de factuur getoond word.</div>
                </b-col>
                <b-col cols="12" lg="6" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Referentie"
                  >
                    <b-form-group
                      label="Referentie"
                      label-for="Referentie"
                    >
                      <b-form-input
                        id="reference"
                        v-model="settingsData.reference"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="6">
                  <label class="col-form-label"><strong>Verloningsperiode</strong></label>
                  <div>Kies hier de periode waarin je verloond wilt worden.</div>
                </b-col>
                <b-col cols="12" lg="6" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Verloningsperiode"
                    rules="required"
                  >
                    <b-form-group
                      label=""
                      label-for="paycheckPeriod"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="paycheckPeriod"
                        v-model="settingsData.paycheckPeriod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paycheckPeriod"
                        :reduce="val => val.key"
                        label="value"
                        :clearable="false"
                        @input="setSelectedPayPeriod"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1 mb-1">
                <b-col cols="12" lg="6">
                  <label class="col-form-label"><strong>Gewerkte uren en dagen</strong></label>
                  <div>Het is belangrijk dat we de juiste uren en dagen verwerken in jouw loonaangifte. Er is standaard
                    een schatting ingevuld.
                  </div>
                </b-col>
                <b-col cols="12" lg="3" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Uren"
                    rules="required"
                  >
                    <b-form-group
                      label="Uren"
                      label-for="paycheckHours"
                    >
                      <b-form-input
                        id="paycheckHours"
                        v-model="settingsData.paycheckHours"
                        :state="getValidationState(validationContext)"
                        type="number"
                        @input="checkMinimumTariff"
                        @keypress="isNumber($event)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" lg="3" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Dagen"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Dagen"
                      label-for="paycheckDays"
                    >
                      <b-form-input
                        id="paycheckDays"
                        name="paycheckDays"
                        v-model="settingsData.paycheckDays"
                        :state="getValidationState(validationContext)"
                        type="number"
                        @keypress="isNumber($event)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" lg="6">
                  <label class="col-form-label"><strong>Betaaltermijn</strong></label>
                  <div>Kies hier de maximale betaaltermijn.</div>
                </b-col>
                <b-col cols="12" lg="6" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="Betaaltermijn"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Betaaltermijn (in dagen)"
                      label-for="expiryDays"
                    >
                      <b-form-input
                        id="expiryDays"
                        v-model="settingsData.expiryDays"
                        :state="getValidationState(validationContext)"
                        type="number"
                        @keypress="isNumber($event)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <b-col
                    cols="6" class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      @click="$emit('previous-step')"
                    >
                      <span>Vorige</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6" class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                    >
                      <span>Volgende</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </section>
        </b-form>
      </validation-observer>
    </template>

    <invoice-summary
      :selected-company-data="selectedCompanyData"
      :selected-btw-data="selectedBtwData"
      :selected-pay-period-data="selectedPayPeriodData"
      :total-amount-ex-btw-data="totalAmountExBtwData"
      :total-btw-data="totalBtwData"
      :total-amount-inc-btw-data="totalAmountIncBtwData"
      :preview-calculation-data="previewCalculationData"
    ></invoice-summary>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { formatDate, getFullName, isNumber } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { integer, required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import InvoiceSummary from '@/views/pages/invoices/component/InvoiceSummary.vue'
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    InvoiceSummary,
    // BSV
    BButton,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    vSelect,
    BAlert,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  data() {
    return {
      settingsData: {
        paycheckPeriod: null,
        paycheckHours: null,
        paycheckDays: null,
        expiryDays: null,
        description: null,
        reference: null,
      },
      minimumTariffCheckError: false,
      paycheckPeriod: [],
    }
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    selectedCompanyData: {
      type: Object,
      default: () => {
      },
    },
    minimumTariff: {
      type: Number,
      default: () => 0,
    },
    selectedBtwData: {
      type: Object,
      default: () => null,
    },
    selectedPayPeriodData: {
      type: Object,
      default: () => null,
    },
    totalAmountExBtwData: {
      type: Number,
      default: () => 0,
    },
    totalBtwData: {
      type: Number,
      default: () => 0,
    },
    totalAmountIncBtwData: {
      type: Number,
      default: () => 0,
    },
    previewCalculationData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    selectedCompanyData: {
      deep: true,
      async handler(data) {
        this.settingsData.expiryDays = data.paymentTermDays ? data.paymentTermDays : 14
      },
    },
  },
  methods: {
    getPayPeriods() {
      store
        .dispatch('app-invoices-add/fetchPayPeriods')
        .then(response => {
          this.$emit('set-pay-check-periods', response.data)
          this.paycheckPeriod = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Verloningsperiode',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    checkMinimumTariff() {
      this.minimumTariffCheckError = (this.totalAmountExBtwData / +this.settingsData.paycheckHours) < this.minimumTariff
    },
    async onSubmit() {
      await this.$emit('set-settings-data', this.settingsData)
      await this.$emit('next-step')
    },
    setSelectedPayPeriod(key) {
      const period = this.paycheckPeriod.filter(b => b.key === key)[0].key
      this.$emit('set-selected-payment-periode', period)
    },
  },
  beforeMount() {
    this.getPayPeriods()
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      required,
      getFullName,
      formatDate,
      integer,
      isNumber,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
