<template>
  <div class="list-view product-checkout mt-0">
    <template>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refStepEmailSettingsObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <section class="invoice-add-wrapper">
            <b-card
              title="E-mailgegevens"
            >
<!--              <b-row>-->
<!--                <b-col cols="12" md="6">-->
<!--                  <label for="cc" class="col-form-label"><strong>E-mail taal</strong></label>-->
<!--                  <div>Geef hier aan in welke taal de factuur e-mail verstuurd moet worden.</div>-->
<!--                </b-col>-->
<!--                <b-col cols="12" md="6" align-self="end">-->
<!--                  <validation-provider-->
<!--                    #default="validationContext"-->
<!--                    name="E-mail taal"-->
<!--                    rules="required"-->
<!--                  >-->
<!--                    <b-form-group-->
<!--                      label=""-->
<!--                      label-for="language"-->
<!--                      :state="getValidationState(validationContext)"-->
<!--                    >-->
<!--                      <v-select-->
<!--                        id="language"-->
<!--                        v-model="emailSettings.language"-->
<!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
<!--                        :options="language"-->
<!--                        :reduce="val => val.value"-->
<!--                        :clearable="false"-->
<!--                      />-->
<!--                      <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
<!--                        {{ validationContext.errors[0] }}-->
<!--                      </b-form-invalid-feedback>-->
<!--                    </b-form-group>-->
<!--                  </validation-provider>-->
<!--                </b-col>-->
<!--              </b-row>-->
              <b-row>
                <b-col cols="12" md="6">
                  <label for="cc" class="col-form-label"><strong>CC e-mailadres</strong></label>
                  <div>Geef hier de e-mailadres op waar deze factuur nog meer naar toe moet worden verzonden.</div>
                </b-col>
                <b-col cols="12" md="6" align-self="end">
                  <validation-provider
                    #default="validationContext"
                    name="cc"
                    rules="email"
                  >
                    <b-form-group
                    >
                      <b-form-input
                        v-model="emailSettings.cc"
                        id="cc"
                        placeholder=""
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="12" md="6">
                  <label class="col-form-label"><strong>E-mail bijlagen</strong></label>
                  <div>Voeg hier eventueel bijlagen toe aan de e-mail. De groote van de file mag maximaal 25mb zijn.
                  </div>
                </b-col>
                <b-col cols="12" md="6" align-self="center">
                  <b-row class="p-0 m-0">
                    <b-col
                      cols="8"
                      class="pl-0"
                    >
                      <b-form-group
                      >
                        <b-form-input
                          v-model="fileName"
                          id="fileName"
                          readonly="readonly"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="4"
                      class="align-items-center"
                    >
                      <b-button
                        v-if="!emailSettings.fileID"
                        style="margin-top: 5px"
                        v-b-toggle.report-list
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        @click="$refs.refInputEl1.click()"
                      >
                        <b-spinner
                          small
                          class="mr-1"
                          v-if="showUploadLoader"
                        />
                        <input
                          ref="refInputEl1"
                          type="file"
                          class="d-none"
                          @input="uploadImage()"
                        >
                        <span class="d-none d-sm-inline">Toevoegen</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                      <b-button
                        v-if="emailSettings.fileID"
                        style="margin-top: 5px"
                        v-b-toggle.report-list
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        size="sm"
                        variant="outline-primary"
                        @click="removeFile"
                      >
                        <span class="d-none d-sm-inline">Verwijderen</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="12" md="6">
                  <label class="col-form-label"><strong>E-mail tekst</strong></label>
                  <div>Vul hier eventueel een tekst in die in e-mail wordt getoond.</div>
                </b-col>
                <b-col cols="12" md="6" align-self="end">
                  <b-form-group
                  >
                    <b-form-textarea
                      v-model="emailSettings.message"
                      rows="4"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr class="invoice-spacing">

              <!-- Note -->
              <b-card-body class="invoice-padding pt-0">
                <b-row>
                  <b-col
                    cols="6 text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      @click="$emit('previous-step')"
                      :disabled="showUploadLoader"
                    >
                      <span>Vorige</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="6 text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      :disabled="showUploadLoader"
                    >
                      <b-spinner
                        small
                        class="mr-1"
                        v-if="showCreateInvoiceSpinner"
                      />
                      <span>Verzenden</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </section>
        </b-form>
      </validation-observer>
    </template>

    <invoice-summary
      :selected-company-data="selectedCompanyData"
      :selected-btw-data="selectedBtwData"
      :selected-pay-period-data="selectedPayPeriodData"
      :total-amount-ex-btw-data="totalAmountExBtwData"
      :total-btw-data="totalBtwData"
      :total-amount-inc-btw-data="totalAmountIncBtwData"
      :preview-calculation-data="previewCalculationData"
    ></invoice-summary>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BRow,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import { formatDate, getFullName } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { email, required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import InvoiceSummary from '@/views/pages/invoices/component/InvoiceSummary.vue'
import store from '@/store'

export default {
  components: {
    InvoiceSummary,
    // BSV
    BButton,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      emailSettings: {
        language: 'nl',
        cc: null,
        fileID: null,
        message: null,
      },
      fileName: null,
      showUploadLoader: false,
      language: [
        {
          label: 'Nederlands',
          value: 'nl',
        },
      ],
    }
  },
  props: {
    showCreateInvoiceSpinner: {
      type: Boolean,
      default: () => false,
    },
    profileData: {
      type: Object,
      default: () => {
      },
    },
    selectedCompanyData: {
      type: Object,
      default: () => {
      },
    },
    selectedBtwData: {
      type: Object,
      default: () => null,
    },
    selectedPayPeriodData: {
      type: Object,
      default: () => null,
    },
    totalAmountExBtwData: {
      type: Number,
      default: () => 0,
    },
    totalBtwData: {
      type: Number,
      default: () => 0,
    },
    totalAmountIncBtwData: {
      type: Number,
      default: () => 0,
    },
    previewCalculationData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onSubmit() {
      this.$emit('set-invoice-email-data', this.emailSettings)
    },
    uploadImage() {
      this.showUploadLoader = true
      const file = document.querySelector('input[type=file]').files[0]
      if (file.size > 25000000) {
        this.showUploadLoader = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 25mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'User',
              name: file.name,
              relationID: userData.id,
            }

            store.dispatch('app-invoices-add/uploadFile', fileUploadData)
              .then(response => {
                this.showUploadLoader = false
                this.fileName = file.name
                this.emailSettings.fileID = response.data.fileID
              })
              .catch(() => {
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer later het nog een keer.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )
        reader.readAsDataURL(file)
      }
    },
    removeFile() {
      this.fileName = null
      this.emailSettings.fileID = null
    },
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
      required,
      getFullName,
      formatDate,
      email,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
